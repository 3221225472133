import { styled } from "@linaria/react";
import {
  type ClipboardEventHandler,
  type FormContents,
  type FormSubmitEvent,
  type FunctionComponent,
  useCallback,
  useState,
} from "react";

import {
  ConversationCreateErrors,
  useConversations,
  useConversationFromProject,
} from "~/hooks/useConversation";
import { useConversationLanguage } from "~/hooks/useConversationLanguage";
import { useLogger } from "~/hooks/useLogger";
import { useProjects } from "~/hooks/useProjects";
import { text } from "~/styles/typography";
import { parseDate, parseTime, areDatesEqual } from "~/utils/datetime";
import {
  QueryError,
  QueryErrorReason,
  validateQueryString,
} from "~/utils/query";

import DismissableMessage, { NotifyType } from "./DismissableMessage";
import ModalDialog from "./ModalDialog";
import { DatePicker, TimeField } from "./library/DatePicker";
import Form, { type FormProps } from "./library/Form";
import Input from "./library/Input";
import { RadioGroup, Radio } from "./library/Radio";
import { Select, Option } from "./library/Select";

interface AddEditConversationFormProps extends FormProps {
  onDismiss: () => void;
  conversationId?: string;
  projectId: string;
  isRerun?: boolean; // When this prop is present, it forces a rerun
}

interface ConversationFormElements {
  project: HTMLInputElement;
  query_string: HTMLInputElement;
  conversation_name: HTMLInputElement;
  conversation_time_period: HTMLInputElement;
  start_date: HTMLInputElement;
  start_hours: HTMLInputElement;
  end_date: HTMLInputElement;
  end_hours: HTMLInputElement;
  conversation_language: HTMLInputElement;
}

export enum ConversationTimePeriod {
  DateRange = "conversation-date-range",
  ContinousQuery = "conversation-continous-query",
}

const StyledForm = styled(Form)`
  > * {
    margin-top: var(--spacing-xl);
  }
`;

const ExampleQuery = styled.div`
  margin-top: var(--spacing-xs);
  ${text.sm.regular};
  line-height: 1.4;
  color: var(--color-text-secondary);
`;

const RuleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-4xl);
  margin-top: var(--spacing-3xl);
  padding-bottom: var(--spacing-xl);
  ${text.xs.regular};
  color: var(--color-gray-900);
  line-height: 16px;
`;

const RuleTitle = styled.p`
  margin-bottom: var(--spacing-2xl);
  ${text.sm.semibold};
  line-height: 1.42;
  color: var(--color-black);
`;

const RuleSection = styled.div`
  dl {
    display: grid;
    grid-template-columns: 32px max-content; /* 32px is a width, not a gutter */
    grid-gap: var(--spacing-lg) var(--spacing-3xl);
    padding-left: var(--spacing-xl);
    ${text.sm.regular};
  }
`;

const ConversationTimeSelectionRadioGroup = styled(RadioGroup)`
  margin-top: var(--spacing-3xl);
`;

const ConversationTimeSelectionRadio = styled(Radio)`
  margin-top: var(--spacing-xl);
  padding-left: var(--spacing-xl);
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: var(--spacing-xl);
  margin-top: var(--spacing-lg);
  padding-left: var(--spacing-6xl);
`;

const ErrorMessage = styled.span`
  ${text.sm.regular};
  line-height: 18px;
  max-width: 400px;
`;

const LanguageDropdown = styled.div`
  width: 255px;
  margin-top: var(--spacing-3xl);
`;

function insertText(text: string) {
  /* execCommand is deprecated and may be removed in the future, but it's still
   * the preferred way to do this specific task because it adds an entry to the
   * undo stack. */
  if (typeof document.execCommand === "function") {
    document.execCommand("insertText", false, text);
  } else {
    const selection = document.getSelection();
    if (!selection?.rangeCount) {
      return;
    }

    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(text));
  }
}

const useAddEditConversationForm = (props: AddEditConversationFormProps) => {
  const { onDismiss, conversationId, isRerun, projectId, ...otherProps } =
    props;
  const logger = useLogger();
  const { create } = useConversations(projectId);
  const { data: conversation, update } = useConversationFromProject(
    conversationId ?? "",
    { projectId },
  );
  const { data: projects } = useProjects();
  const {
    languageOptions,
    conversationLanguage, // User selected or default language
  } = useConversationLanguage();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [conversationTimePeriod, setConversationTimePeriod] =
    useState<ConversationTimePeriod>(
      conversation?.threat_feed
        ? ConversationTimePeriod.ContinousQuery
        : ConversationTimePeriod.DateRange,
    );

  const updateConversationTimePeriod = (conversationTimePeriod: string) => {
    setConversationTimePeriod(
      conversationTimePeriod === ConversationTimePeriod.DateRange
        ? ConversationTimePeriod.DateRange
        : ConversationTimePeriod.ContinousQuery,
    );
  };
  const onPasteQuery: ClipboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.preventDefault();
      const text = event.clipboardData.getData("text");
      const replaced = text
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"');
      insertText(replaced);
    },
    [],
  );
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();
      const form = e.currentTarget as FormContents<ConversationFormElements>;
      const submitButton = e.nativeEvent.submitter;

      submitButton.setAttribute("disabled", "disabled");

      /* If there is a date and time provided, we pass in the user selected values.
       * If there is a date but no time provided, we default the time to 00:00:00.
       * If there is no date provided, we send the backend undefined, and the backend sets the date range to the default range (last 3 days)
       */
      const startHours = form.elements.start_hours?.value
        ? `${form.elements.start_hours.value}+00:00`
        : "";
      const endHours = form.elements.end_hours?.value
        ? `${form.elements.end_hours.value}+00:00`
        : "";
      const startDate = form.elements.start_date?.value
        ? `${form.elements.start_date.value}T${startHours}`
        : undefined;
      const endDate = form.elements.end_date?.value
        ? `${form.elements.end_date.value}T${endHours}`
        : undefined;
      const language = form.elements.conversation_language.value;
      const queryString = form.elements.query_string.value;

      try {
        validateQueryString(queryString);
      } catch (ex) {
        submitButton.removeAttribute("disabled");

        if (ex instanceof QueryError) {
          switch (ex.msg) {
            case QueryErrorReason.ConsecutiveKeywords: {
              setErrorMessage(
                `multiple keywords found starting at position ${
                  ex.position
                }: "${queryString.slice(ex.position)}"`,
              );

              return;
            }
            case QueryErrorReason.EmptyParens: {
              setErrorMessage(
                `empty parentheses found at position ${ex.position}`,
              );

              return;
            }
            case QueryErrorReason.LowercaseKeyword: {
              setErrorMessage(
                `non-uppercase keyword found at position ${
                  ex.position
                }: "${queryString.slice(ex.position)}"`,
              );

              return;
            }
            case QueryErrorReason.MissingParen: {
              setErrorMessage(
                `unbalanced parentheses starting at position ${
                  ex.position
                }: "${queryString.slice(ex.position)}"`,
              );

              return;
            }
            case QueryErrorReason.MissingQuote: {
              setErrorMessage(
                `unbalanced quotes starting at position ${
                  ex.position
                }: "${queryString.slice(ex.position)}"`,
              );

              return;
            }
            case QueryErrorReason.NoInput: {
              setErrorMessage(`no query string provided`);

              return;
            }
            case QueryErrorReason.UnbalancedKeyword: {
              setErrorMessage(
                `keyword at position ${
                  ex.position
                } is missing its right-hand side: "${queryString.slice(
                  ex.position,
                )}"`,
              );

              return;
            }
            case QueryErrorReason.SmartQuote: {
              setErrorMessage(`smart-quote found at position ${ex.position}`);

              return;
            }
          }
        } else {
          setErrorMessage(String(ex));
        }
      }

      if (conversation) {
        /* Refresh the cache if the query string or date range changes.
         * We need to make sure the new start and end dates are in UTC when
         * we compare them. We're also counting on areDatesEqual to return
         * false if either date is incorrectly formatted, which will be the
         * case when the user has cleared startHours or endHours. */
        const shouldRefresh =
          conversation.query_string !== form.elements.query_string.value ||
          !startDate ||
          !areDatesEqual(startDate, conversation.start_date) ||
          !endDate ||
          !areDatesEqual(endDate, conversation.end_date);

        try {
          await update(
            {
              project_id: form.elements.project.value,
              query_string: form.elements.query_string.value,
              name: form.elements.conversation_name.value,
              threat_feed: conversation.threat_feed,
              start_date: startDate,
              end_date: endDate,
              rerun: isRerun,
              language: language,
              use_existing_data: conversation.use_existing_data,
            },
            { refresh: shouldRefresh },
          );

          onDismiss();
        } catch (ex: any) {
          logger.error(ex.message);
          setErrorMessage(
            "There was an error editing your conversation.  Please wait a moment and try again.",
          );
        }
      } else {
        try {
          await create({
            project_id: projectId,
            query_string: form.elements.query_string.value,
            name: form.elements.conversation_name.value,
            threat_feed:
              form.elements.conversation_time_period.value ===
              ConversationTimePeriod.ContinousQuery,
            start_date: startDate,
            end_date: endDate,
            language: language,
          });
          onDismiss();
        } catch (ex: any) {
          if (
            ex.message ===
            String(ConversationCreateErrors.DataSourceQuotaLimitError)
          ) {
            setErrorMessage(
              "The third-party data source query quota has been reached. Please contact your organization's administrator.",
            );
          } else if (
            ex.message === String(ConversationCreateErrors.QuerySyntaxError)
          ) {
            setErrorMessage(
              "There was an error with your query string input. Please review the query language syntax and try again. Operators are case-sensitive and quotes are required for special characters, non-English characters, and multi-word key-phrases.",
            );
          } else if (
            ex.message === String(ConversationCreateErrors.TimeRangeError)
          ) {
            setErrorMessage(
              "There was an error with one of your date or time fields. The date must be in the format yyyy-mm-dd and the time must be in the format hh:mm.",
            );
          } else {
            setErrorMessage(
              "There was an error creating your conversation.  Please wait a moment and try again.",
            );
          }
        }
      }

      submitButton.removeAttribute("disabled");
    },
    [create, conversation, isRerun, logger, onDismiss, projectId, update],
  );
  const dismissErrorDialog = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  return {
    conversation,
    conversationLanguage,
    conversationTimePeriod,
    dismissErrorDialog,
    errorMessage,
    formProps: {
      ...otherProps,
      "aria-label": `${conversation ? "Edit" : "Create"} Conversation`,
    },
    languageOptions,
    onDismiss,
    onPasteQuery,
    onSubmit,
    projects,
    updateConversationTimePeriod,
  };
};

const AddEditConversationForm: FunctionComponent<
  AddEditConversationFormProps
> = (props) => {
  const {
    conversation,
    conversationLanguage,
    conversationTimePeriod,
    dismissErrorDialog,
    errorMessage,
    formProps,
    languageOptions,
    onPasteQuery,
    onSubmit,
    projects,
    updateConversationTimePeriod,
  } = useAddEditConversationForm(props);

  return (
    <StyledForm {...formProps} onSubmit={onSubmit}>
      {conversation && (
        <Select
          defaultSelectedKey={conversation.project_id}
          label="Project"
          name="project"
          required
        >
          {projects?.map((project) => (
            <Option key={project.id} id={project.id}>
              {project.name}
            </Option>
          ))}
        </Select>
      )}
      <Input
        data-1p-ignore
        data-lpignore
        defaultValue={conversation?.name}
        label="Conversation Name"
        name="conversation_name"
        placeholder="My conversation"
        required
        type="text"
      />
      <Input
        data-1p-ignore
        data-lpignore
        defaultValue={conversation?.query_string}
        label="Boolean String"
        name="query_string"
        onPaste={onPasteQuery}
        placeholder={'"United States" AND (England OR UK) NOT Canada'}
        required
        type="text"
      />
      <ExampleQuery>
        Example: "United States" AND (England OR UK) NOT Canada
      </ExampleQuery>

      <ConversationTimeSelectionRadioGroup
        label="Select Conversation Time Period"
        name="conversation_time_period"
        onChange={updateConversationTimePeriod}
        value={conversationTimePeriod}
      >
        <ConversationTimeSelectionRadio
          description="If you do not provide a date range, the last 3 days will be used. Times are in Coordinated Universal Time (UTC)."
          disabled={conversation && conversation?.threat_feed}
          value={ConversationTimePeriod.DateRange}
        >
          Date Range
        </ConversationTimeSelectionRadio>
        {conversationTimePeriod === ConversationTimePeriod.DateRange && (
          <DateContainer>
            <DatePicker
              defaultValue={
                conversation
                  ? parseDate(conversation.start_date.substring(0, 10))
                  : undefined
              }
              label="Start Date"
              name="start_date"
            />
            <TimeField
              aria-label="Start Time"
              defaultValue={
                conversation
                  ? parseTime(conversation.start_date.substring(11, 16))
                  : undefined
              }
              label="Time"
              name="start_hours"
            />
            <DatePicker
              defaultValue={
                conversation
                  ? parseDate(conversation.end_date.substring(0, 10))
                  : undefined
              }
              label="End Date"
              name="end_date"
            />
            <TimeField
              aria-label="End Time"
              defaultValue={
                conversation
                  ? parseTime(conversation.end_date.substring(11, 16))
                  : undefined
              }
              label="Time"
              name="end_hours"
            />
          </DateContainer>
        )}

        <ConversationTimeSelectionRadio
          description="Re-runs everyday, gathering data from the previous 7 days."
          disabled={conversation && !conversation?.threat_feed}
          value={ConversationTimePeriod.ContinousQuery}
        >
          Set as Continuous Query
        </ConversationTimeSelectionRadio>
      </ConversationTimeSelectionRadioGroup>

      <LanguageDropdown>
        <Select
          defaultSelectedKey={
            conversation?.language ??
            conversationLanguage ??
            languageOptions[0].id
          }
          items={languageOptions}
          label="Conversation Language"
          name="conversation_language"
        >
          {(item) => <Option id={item.id}>{item.label}</Option>}
        </Select>
      </LanguageDropdown>

      <RuleContainer>
        <RuleSection>
          <RuleTitle>Boolean Operators (case sensitive)</RuleTitle>
          <dl>
            <dt>AND</dt>
            <dd>
              Both terms need to be present to show in search results.
              <br />
              Example: red AND white
            </dd>
            <dt>NOT</dt>
            <dd>
              Cannot return a result with any of the terms.
              <br />
              Example: red NOT white
            </dd>
            <dt>OR</dt>
            <dd>
              Either term needs to be present to show in search results.
              <br />
              Example: red OR white
            </dd>
          </dl>
        </RuleSection>
        <RuleSection>
          <RuleTitle>Search Modifiers</RuleTitle>
          <dl>
            <dt>
              " "
              <br />' '
            </dt>
            <dd>
              Quotes - Single or double quotes can specify multi-word phrases.
              <br />
              Quotes are also required for special or non-English characters.
              <br />
              Example: 'baby blue', "$pecial%#chars*"
            </dd>
            <dt>(&nbsp;)</dt>
            <dd>
              Parentheses - Parentheses group terms and specify an order
              <br />
              of precedence. Without them, the default order of precedence
              <br />
              is NOT, then AND, then OR.
            </dd>
          </dl>
        </RuleSection>
      </RuleContainer>

      <ModalDialog isOpen={!!errorMessage}>
        <DismissableMessage
          onClose={dismissErrorDialog}
          type={NotifyType.Error}
        >
          <ErrorMessage>{errorMessage ?? ""}</ErrorMessage>
        </DismissableMessage>
      </ModalDialog>
    </StyledForm>
  );
};

export default AddEditConversationForm;
