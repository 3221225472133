import { type Location, createPath } from "react-router-dom";

import { useCurrentUser } from "../hooks/useCurrentUser";

export const GRAPH_PARENT_KEY = "viz";

const getLoginRedirect = (location: Location) => {
  return location.pathname === "/" || location.pathname === "/login"
    ? "/login"
    : `/login?next=${encodeURIComponent(createPath(location))}`;
};

const urlForDashboardTab = (locationPathname: string, siblingTab: string) => {
  return locationPathname.includes("conversation-overview")
    ? locationPathname.replace("conversation-overview", siblingTab)
    : locationPathname.concat(`/${siblingTab}`).replace("//", "/");
};

const MAIN_TABS = ["dashboard", "alerts", "explorer", "insights"];

const SUB_TABS = ["network-navigator", "overview", "posts"];

interface ConversationExplorerOptions {
  params?: URLSearchParams;
  hash?: string;
}

const urlForConversationExplorerTab = (
  locationPathname: string,
  tabType: "main" | "sub",
  tab: string,
  options: ConversationExplorerOptions = {},
) => {
  const { hash, params = new URLSearchParams() } = options;

  const hashString = hash ? (hash.startsWith("#") ? hash : `#${hash}`) : "";

  if (locationPathname.includes(tab)) {
    return locationPathname + `?${params}` + hashString;
  }

  if (tabType === "main") {
    if (!MAIN_TABS.includes(tab)) {
      throw new Error(
        `Invalid main tab: ${tab}. Expected "${MAIN_TABS.join('" or "')}".`,
      );
    }

    /*
    The regraph is driven by the url params. This is to set the params when user switches to the network-navigator
    when graph preset is not set.
    */
    const narrativeParams = new URLSearchParams();
    const narrativeDefaultParams = {
      [`${GRAPH_PARENT_KEY}:preset`]: "narrative",
      [`${GRAPH_PARENT_KEY}:colorGroupBy`]: "threatLevel",
      [`${GRAPH_PARENT_KEY}:cluster`]: "topicId",
      [`${GRAPH_PARENT_KEY}:node`]: "posts",
    };
    if (tab === "explorer" && !params?.has(`${GRAPH_PARENT_KEY}:preset`)) {
      Object.entries(narrativeDefaultParams).forEach(([key, value]) => {
        narrativeParams.set(key, value);
      });
    }
    const combinedParams = new URLSearchParams([
      ...params,
      ...narrativeParams,
    ]).toString();

    const incluesMainTab = MAIN_TABS.some((tab) =>
      locationPathname.includes(tab),
    );

    if (!incluesMainTab) {
      return `${locationPathname}/${tab}` + `?${combinedParams}` + hashString;
    }

    return (
      locationPathname.replace(
        new RegExp(`(${MAIN_TABS.join("|")}).*`),
        tab + `?${combinedParams}`,
      ) + hashString
    );
  }

  if (!SUB_TABS.includes(tab)) {
    throw new Error(
      `Invalid subtab: ${tab}. Expected "${SUB_TABS.join('" or "')}".`,
    );
  }

  if (!/dashboard|explorer/.test(locationPathname)) {
    //usually the case when we open the conversation for the first time -> default to dashboard without the string in the url
    return (
      (locationPathname.endsWith("/")
        ? `${locationPathname}explorer/${tab}`
        : `${locationPathname}/explorer/${tab}`) +
      `?${params}` +
      hashString
    );
  }

  return (
    locationPathname.replace(/(dashboard|explorer).*/, `explorer/${tab}`) +
    `?${params}` +
    hashString
  );
};

const INTERNAL_ORG_IDS = [
  "9722b669-aa90-4f38-ab57-706138164d10",
  "44f2ca95-f416-40c3-ae10-707d0211ca4a",
  "60a3b714-d89e-490c-a3db-e3c5a534cb24",
  "f626d039-7f37-4653-a894-472379c13e2a",
  "4253352a-cefe-4c4b-811b-2df0d6fb0858",
];

function useIsInternalOrg() {
  const { currentUser } = useCurrentUser();

  return (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test" ||
    INTERNAL_ORG_IDS.includes(currentUser?.organization_id ?? "")
  );
}

const addQueryParamsToUrl = (
  url: URL | string,
  params: Record<string, string>,
) => {
  const oldUrl = new URL(url);
  const newParams = new URLSearchParams({
    ...Object.fromEntries(oldUrl.searchParams.entries()),
    ...params,
  });

  return new URL(`${oldUrl.origin}${oldUrl.pathname}?${newParams.toString()}`);
};

const getRelativeUrl = (
  relativePath: string,
  opts?: { queryParams?: "keep" | "replace" | "merge" },
) => {
  const { queryParams = "keep" } = opts ?? {};
  const [path, params = ""] = relativePath.split("?");

  if (queryParams === "keep") {
    return new URL(
      `${path.replaceAll("//", "/")}${document.location.search}`,
      document.location.href,
    ).toString();
  } else if (queryParams === "merge") {
    const newParams = new URLSearchParams({
      ...Object.fromEntries(new URLSearchParams(document.location.search)),
      ...Object.fromEntries(new URLSearchParams(params)),
    });

    return new URL(
      `${path.replaceAll("//", "/")}${newParams.toString()}`,
      document.location.href,
    ).toString();
  } else {
    return new URL(
      `${path.replaceAll("//", "/")}?${params}`,
      document.location.href,
    ).toString();
  }
};

export {
  addQueryParamsToUrl,
  getLoginRedirect,
  getRelativeUrl,
  urlForDashboardTab,
  urlForConversationExplorerTab,
  useIsInternalOrg,
};
