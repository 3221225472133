import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import {
  type CSSProperties,
  type ReactElement,
  type FunctionComponent,
  useCallback,
  useState,
} from "react";
import {
  type NumberFieldProps,
  type TextFieldProps,
  FieldError,
  Input as HTMLInput,
  Label,
  Text,
  TextArea,
  TextField,
  NumberField,
  Group,
} from "react-aria-components";

import { text } from "~/styles/typography";

import { FormSize, useFormSize } from "./Form";
import Icon from "./Icon";
import IconButton, { ButtonKind, ButtonSize } from "./IconButton";
import {
  fieldLabel,
  fieldError,
  fieldDescription,
  Required,
} from "./formStyles";

type BaseInputProps = Omit<
  TextFieldProps,
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
  | "className"
  | "style"
  | "children"
> & {
  className?: string;
  style?: CSSProperties;
  disabled?: TextFieldProps["isDisabled"];
  readOnly?: TextFieldProps["isReadOnly"];
  required?: TextFieldProps["isRequired"];

  label?: string;
  description?: string;
  error?: string;
  size?: FormSize;

  // for some reason, TextFieldProps specifically removes the placeholder prop but still passes it to the input
  placeholder?: string;
};

const InputContent = styled.div`
  display: flex;
  flex-grow: 1;
  border-width: 1px;
  border-style: solid;
  gap: var(--spacing-md);
  overflow: hidden;
  z-index: 1;

  input,
  textarea {
    flex-grow: 1;

    &:not(:disabled) {
      -webkit-background-clip: text;
      box-shadow: inset 0 0 20px 20px var(--background-color-primary);
    }
  }

  [data-icon] {
    font-size: 16px;
    color: var(--foreground-color-quinary);
    flex-shrink: 0;
    align-self: center;
  }
`;

const InputWrapper = styled(Group)`
  display: flex;
  border-radius: var(--border-radius-md);
  isolation: isolate;

  input,
  textarea {
    background: none;
    color: var(--text-color-primary);

    &:focus {
      outline: none;
    }
  }

  & > *,
  input,
  textarea {
    ${text.md.regular}
  }

  & > *:not(button) {
    color: var(--foreground-color-tertiary);
  }

  && > * {
    border: 1px solid var(--border-color-primary);
    border-radius: 0;

    &:first-child {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);

      &:not(${InputContent}) {
        border-right: none;
      }
    }

    &:last-child {
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);

      &:not(${InputContent}) {
        border-left: none;
      }
    }
  }
`;

const inputRoot = css`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  ${InputContent} {
    background-color: var(--background-color-primary);
    border-color: var(--border-color-primary);

    &:has([data-focused]) {
      box-shadow: var(--ring-brand-shadow-xs);
    }
  }

  &[data-size="${FormSize.sm}"] {
    ${InputWrapper} > * {
      padding: var(--spacing-md) var(--spacing-lg);
    }
  }

  &[data-size="${FormSize.md}"] {
    ${InputWrapper} > * {
      // these don't line up with our spacing scale, so we just use fixed values
      padding: 10px 14px;
    }
  }

  &[data-invalid] {
    ${InputContent} {
      border-color: var(--border-color-error);

      &:has([data-focused]) {
        box-shadow: var(--ring-error-shadow-xs);
      }
    }

    // hide the description only if there is an error message to show instead
    [slot="description"]:has(+ [slot="errorMessage"]:not(:empty)) {
      display: none;
    }
  }

  &[data-disabled] {
    ${InputWrapper} > *:not(button) {
      background-color: var(--background-color-disabled_subtle);
      border-color: var(--border-color-disabled);
    }

    input,
    textarea {
      color: var(--text-color-disabled);
    }
  }
`;

type TextInputProps = BaseInputProps & {
  preIcon?: ReactElement;
  postIcon?: ReactElement;
  preElement?: ReactElement;
  postElement?: ReactElement;
};

const TextInput: FunctionComponent<TextInputProps> = (props) => {
  const {
    label,
    description,
    error,
    preIcon,
    postIcon,
    preElement,
    postElement,
    size: _size,
    disabled,
    readOnly,
    required,
    className,
    ...rest
  } = props;

  const { size } = useFormSize(props);

  return (
    <TextField
      {...rest}
      className={cx(inputRoot, className)}
      data-required={required || undefined}
      data-size={size}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <InputWrapper>
        {preElement}
        <InputContent>
          {preIcon}
          <HTMLInput />
          {postIcon}
        </InputContent>
        {postElement}
      </InputWrapper>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>
    </TextField>
  );
};

type TextAreaProps = BaseInputProps & {
  type: "textarea";
};

const isTextArea = (props: InputProps): props is TextAreaProps =>
  props.type === "textarea";

const TextAreaInput: FunctionComponent<TextAreaProps> = (props) => {
  const {
    label,
    description,
    error,
    size: _size,
    disabled,
    readOnly,
    required,
    className,
    ...rest
  } = props;

  const { size } = useFormSize(props);

  return (
    <TextField
      {...rest}
      className={cx(inputRoot, className)}
      data-required={required || undefined}
      data-size={size}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      <Label className={fieldLabel}>
        {label}
        <Required />
      </Label>
      <InputWrapper>
        <InputContent>
          <TextArea />
        </InputContent>
      </InputWrapper>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{error}</FieldError>
    </TextField>
  );
};

type PasswordInputProps = BaseInputProps & {
  type: "password";
};

const isPasswordInput = (props: InputProps): props is PasswordInputProps =>
  props.type === "password";

const PasswordInput: FunctionComponent<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <TextInput
      {...props}
      postElement={
        <IconButton
          aria-checked={showPassword}
          aria-label="Show password"
          data-kind={ButtonKind.Secondary}
          data-size={ButtonSize.md}
          icon={showPassword ? "eye" : "eye-off"}
          onPress={toggleShowPassword}
        />
      }
      preIcon={<Icon family="untitled" name="lock-01" />}
      type={showPassword ? "text" : "password"}
    />
  );
};

type HiddenInputProps = BaseInputProps & {
  type: "hidden";
};

const isHiddenInput = (props: InputProps): props is HiddenInputProps =>
  props.type === "hidden";

const HiddenInput: FunctionComponent<HiddenInputProps> = (props) => {
  const {
    label,
    description,
    error,
    size,
    onChange,
    className,
    style,
    slot,
    // we don't care about the above props, so use the rest operator as an "omit" function
    ...rest
  } = props;

  return <input {...rest} type="hidden" />;
};

type NumberInputProps = Omit<
  NumberFieldProps,
  | "className"
  | "style"
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
  | "minValue"
  | "maxValue"
> & {
  type: "number";

  className?: string;
  style?: CSSProperties;
  disabled?: NumberFieldProps["isDisabled"];
  readOnly?: NumberFieldProps["isReadOnly"];
  required?: NumberFieldProps["isRequired"];
  min?: number;
  max?: number;

  label?: string;
  description?: string;
  error?: string;
  size?: FormSize;
  preIcon?: ReactElement;
  postIcon?: ReactElement;
};

const isNumberInput = (props: InputProps): props is NumberInputProps =>
  props.type === "number";

const NumberInput: FunctionComponent<NumberInputProps> = (props) => {
  const {
    label,
    description,
    error,
    preIcon,
    postIcon,
    size: _size,
    disabled,
    readOnly,
    required,
    className,
    min,
    max,
    ...rest
  } = props;

  const { size } = useFormSize(props);

  return (
    <NumberField
      {...rest}
      className={cx(inputRoot, className)}
      data-required={required || undefined}
      data-size={size}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      maxValue={max}
      minValue={min}
    >
      <Label className={fieldLabel}>
        {label}
        <Required />
      </Label>
      <InputWrapper>
        <IconButton
          aria-label="Decrement"
          data-kind={ButtonKind.Secondary}
          data-size={ButtonSize.md}
          icon="minus"
          slot="decrement"
        />
        <InputContent>
          {preIcon}
          <HTMLInput />
          {postIcon}
        </InputContent>
        <IconButton
          aria-label="Increment"
          data-kind={ButtonKind.Secondary}
          data-size={ButtonSize.md}
          icon="plus"
          slot="increment"
        />
      </InputWrapper>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{error}</FieldError>
    </NumberField>
  );
};

export type InputProps =
  | TextInputProps
  | TextAreaProps
  | PasswordInputProps
  | HiddenInputProps
  | NumberInputProps;

const Input: FunctionComponent<InputProps> = (props) => {
  if (isTextArea(props)) return <TextAreaInput {...props} />;
  if (isHiddenInput(props)) return <HiddenInput {...props} />;
  if (isPasswordInput(props)) return <PasswordInput {...props} />;
  if (isNumberInput(props)) return <NumberInput {...props} />;

  return <TextInput {...props} />;
};

export default Input;
