import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import AddEditConversationForm from "./AddEditConversationForm";
import Card from "./Card";
import ModalDialog, {
  DialogBottomContainer,
  DialogContentsContainer,
  DialogHeadingContainer,
  DialogTitle,
  XButton,
} from "./ModalDialog";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Icon from "./library/Icon";

interface AddConversationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  conversationId?: string;
  isRerun?: boolean;
  projectId: string;
}

const DialogHeadingInnerContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: var(--spacing-sm);
`;

const FixedTopDialog = styled(ModalDialog)`
  max-height: 100vh;
  padding-top: 10vh;
  padding-bottom: 5vh;

  && {
    align-items: start;
  }
`;

const PaddedDialogContents = styled(DialogContentsContainer)`
  overflow-y: auto;
`;

const StyledCard = styled(Card)`
  border-radius: var(--border-radius-sm);
  min-width: 560px;
  max-height: 85vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const AddConversationDialog: FunctionComponent<AddConversationDialogProps> = (
  props,
) => {
  const { conversationId, isOpen, isRerun, onClose, projectId } = props;

  return (
    <FixedTopDialog isOpen={isOpen}>
      <StyledCard>
        <DialogHeadingContainer>
          <DialogHeadingInnerContainer>
            <Icon
              family="untitled"
              name={conversationId ? "edit-02" : "plus"}
            />
            <DialogTitle>
              {conversationId ? `Edit Conversation` : `Create Conversation`}
            </DialogTitle>
          </DialogHeadingInnerContainer>
          <XButton onPress={onClose} />
        </DialogHeadingContainer>
        <PaddedDialogContents>
          <AddEditConversationForm
            conversationId={conversationId}
            id="add-conversation-form"
            isRerun={isRerun}
            onDismiss={onClose}
            projectId={projectId}
          />
        </PaddedDialogContents>
        <DialogBottomContainer>
          <Button
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.md}
            onPress={onClose}
            type="button"
          >
            Close
          </Button>
          <Button
            data-kind={ButtonKind.Primary}
            data-size={ButtonSize.md}
            form="add-conversation-form"
            type="submit"
          >
            {conversationId ? `Update` : `Create Conversation`}
          </Button>
        </DialogBottomContainer>
      </StyledCard>
    </FixedTopDialog>
  );
};

export default AddConversationDialog;
